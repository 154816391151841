'use client';

import { Box, Heading } from '@chakra-ui/layout';
import { Flex } from '@chakra-ui/layout';
import { getImageProps } from 'next/image';
import React, { useState } from 'react';
import slugify from 'react-slugify';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { useOpenFloatingCTA } from '@/hooks/floating-cta.hook';
import { assetsUri } from '@/services/api.service';
import type { ImageTransformationKeys } from '@/types/files/files.types';
import type { NewCarData } from '@/types/new-cars/new-cars.types';
import { directusImageLoader } from '@/utils/loaders';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import 'swiper/css/effect-fade';

import './HeroBanner.scss';

import LinkCustom from '../link/LinkCustom';

import styles from './HeroBanner.module.scss';

type HeroBannersT = {
  desktop_image: string;
  mobile_image: string;
  terms_and_conditions: string;
  heading: string;
  subheading: string;
  page: string;
  show_call_to_action: boolean;
  call_to_action_text: string;
  call_to_action_url: string;

  show_call_to_action_2: boolean;
  call_to_action_text_2: string;
  call_to_action_url_2: string;

  show_book_test_drive: boolean;
  test_drive_range: { name: string };
};

type Props = {
  imageKeys: ImageTransformationKeys;
  heroBanners: HeroBannersT[];
  newCars: NewCarData[];
};

const HeroBanner = React.memo(({ imageKeys, heroBanners, newCars }: Props) => {
  const openLeadForm = useOpenFloatingCTA();

  const [currentItem, setCurrentItem] = useState(heroBanners[0]);

  const renderItem = (item: HeroBannersT) => {
    return (
      <Box className="heroBannerContentWrapper">
        <Box className="heroBannerContent">
          <Box className="headingElement">
            <Heading
              as="h1"
              className="titleXl"
              marginBottom={{ base: 4, md: 8 }}
            >
              {item.heading}
            </Heading>
            <Flex alignItems="center" gap="4">
              {item.show_call_to_action && item.call_to_action_text ? (
                <LinkCustom
                  className="btnWhite"
                  moduleClass="btnCta"
                  href={item?.call_to_action_url}
                >
                  {item?.call_to_action_text}
                </LinkCustom>
              ) : null}

              {item?.show_call_to_action_2 && item.call_to_action_text_2 ? (
                <LinkCustom
                  className="btnOutlineWhite"
                  moduleClass="btnCta"
                  href={item?.call_to_action_url_2}
                >
                  {item?.call_to_action_text_2}
                </LinkCustom>
              ) : null}

              {item?.show_book_test_drive && (
                <ButtonCustom
                  btnText="Book A Test Drive"
                  className="btnOutlineWhite"
                  moduleClass="btnCta"
                  onClick={() => openTestDriveModal(item)}
                />
              )}
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  };

  const openTestDriveModal = (item: HeroBannersT) => {
    const newCar = newCars?.find(
      (c) => slugify(c.main_title) === slugify(item.test_drive_range.name),
    );

    if (!newCar) {
      return;
    }

    openLeadForm('test-drive', 'TestDrive', {
      type: 'new-car',
      newCar: newCar,
    });
  };

  return (
    <>
      <Swiper
        effect={'fade'}
        autoplay={{
          delay: 4500,
          disableOnInteraction: true,
        }}
        loop={true}
        navigation={true}
        pagination={{ clickable: true }}
        modules={[EffectFade, Navigation, Pagination, Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
        className="heroSwiper"
        onSlideChange={(s) => setCurrentItem(heroBanners[s.realIndex])}
      >
        {heroBanners &&
          heroBanners?.map((item, index) => {
            const common = {
              alt: 'Hero',
              width: 1440,
              height: 681,
              loader: directusImageLoader,
            };
            const {
              props: { srcSet: mobile },
            } = getImageProps({
              ...common,
              src: `${assetsUri}/${item.mobile_image}?key=${imageKeys.mobileKey}`,
              priority: index === 0,
            });

            const {
              props: { srcSet: desktop, ...rest },
            } = getImageProps({
              ...common,
              src: `${assetsUri}/${item.desktop_image}?key=${imageKeys.desktopKey}`,
              priority: index === 0,
            });

            return (
              <SwiperSlide key={index}>
                <div className="heroSwiperWrap">
                  <picture className={styles.bannerImage}>
                    <source media="(max-width: 575px)" srcSet={mobile} />
                    <source media="(min-width: 576px)" srcSet={desktop} />
                    <img {...rest} alt={item.desktop_image} />
                  </picture>
                  {renderItem(item)}
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <Box className="headingElementMobile">
        <Box
          className="headingElement"
          paddingTop={'64px'}
          paddingBottom={{ base: '33px', md: 12 }}
          paddingX={6}
        >
          <Heading as="h1" className="titleXl" marginBottom={{ base: 8 }}>
            {currentItem?.heading}
          </Heading>
          {currentItem?.show_call_to_action &&
            currentItem?.call_to_action_text && (
              <LinkCustom
                className="btnWhite"
                href={currentItem?.call_to_action_url}
                moduleClass="btnCta"
                extraClass="btnFirst"
              >
                {currentItem?.call_to_action_text?.trim()}
              </LinkCustom>
            )}
          {currentItem?.show_book_test_drive && (
            <ButtonCustom
              btnText="Book A Test Drive"
              className="btnOutlineWhite"
              moduleClass="btnCta"
              onClick={() => openTestDriveModal(currentItem)}
            />
          )}
          {!currentItem?.call_to_action_text && (
            <>
              <LinkCustom className="btnWhite" moduleClass="btnCta">
                {currentItem?.test_drive_range?.name}
              </LinkCustom>
              <ButtonCustom
                btnText="Book A Test Drive"
                className="btnOutlineWhite"
                moduleClass="btnCta"
                onClick={() => openTestDriveModal(currentItem)}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
});

HeroBanner.displayName = 'HeroBanner';

export default HeroBanner;
